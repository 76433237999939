import type { AppContext } from 'types/common'
import type { VehicleOrderBy$options, VehicleSearchResultsData$result, VehicleSearchResultsData$input } from '$houdini'
import type { NumberRange, DateRange, Status, PerPageCount } from 'utility/search-fields'
export type Vehicle = VehicleSearchResultsData$result['vehicles']['items'][0]

import { isValidPerPageCount, buildInventoryQuestionValueFilterArgument } from 'utility/search-fields'
import { parseISO } from 'date-fns'
import { graphql, VehicleOrderBy } from '$houdini'
import Results from './Results.svelte'
import { getSession } from 'stores/session'

const isValidOrderBy = (orderBy: string): orderBy is VehicleOrderBy$options => {
	return Object.keys(VehicleOrderBy).includes(orderBy)
}

function getOrderBy(column: string | undefined, direction: string) {
	let orderBy = column

	if (orderBy) {
		if (orderBy.includes('typeField')) {
			const split = orderBy.split('.')
			orderBy = split[0]
		} else if (orderBy.includes('.')) {
			const split = orderBy.split('.')
			split[1] = split[1].charAt(0).toUpperCase() + split[1].slice(1)
			orderBy = split.join('')
		}

		return `${orderBy}_${direction === 'DESC' ? 'DESC' : 'ASC'}`
	} else {
		return ''
	}
}

const dataQuery = graphql(`
	query VehicleSearchResultsData($filter: VehicleFilter, $pagination: PaginationOptions, $orderBy: [VehicleOrderBy!]) {
		vehicles(filter: $filter, pagination: $pagination, orderBy: $orderBy) {
			totalItems
			pageInfo {
				pageSize
				pageNumber
				totalPages
			}
			items {
				id
				attachmentCount
				attachments {
					id
					public
					rank
					file {
						id
						name
						path: url
						mimetype
						size
					}
				}
				categoryType {
					name
				}
				componentCount
				description
				dismantled
				dismantler {
					name
				}
				make
				model
				receivedDate
				stockNumber
				storeId
				store {
					id
					name
				}
				topImage {
					path: url
				}
				location
				notes
				status
				userStatus
				vin
				year
				wholeUnit {
					id
					retailPrice
					inventoryType {
						name
						typeLabel1
						typeLabel2
						typeLabel3
						typeLabel4
					}
				}
			}
		}
	}
`)

const jsonParseParameters = new Set([
	'STORE',
	'YEAR_RANGE',
	'RETAIL_PRICE',
	'DATE_ENTERED',
	'ENTERED_BY_USER',
	'DISMANTLER',
	'DISMANTLED',
	'perPageCount',
	'pageNumber',
	'ADDITIONAL_FIELDS',
	'QA_FIELDS',
])
interface ParsedStateParameters {
	selectedVehicleId?: number
	pageNumber?: number
	perPageCount?: number
	sortColumn?: VehicleOrderBy$options
	sortDirection?: 'ASC' | 'DESC'
	STORE?: number[]
	INVENTORY_TYPE?: string
	LOCATION?: string
	STATUS?: Status
	USER_STATUS?: string
	CATEGORY?: string
	YEAR_RANGE?: NumberRange
	VEHICLE_MANUFACTURER?: string
	VEHICLE_MODEL?: string
	STOCK_NUMBER?: string
	VIN?: string
	ADJUSTER?: string
	BODY_STYLE?: string
	CLAIM_NUMBER?: string
	JUNKING_NUMBER?: string
	FILE_NUMBER?: string
	EXTERNAL_COLOR?: string
	ENTERED_BY_USER?: number
	DESCRIPTION?: string
	DISMANTLER?: number
	DISMANTLED?: boolean
	DATE_ENTERED?: DateRange
	PICKUP_LOCATION?: string
	PURCHASED_FROM?: string
	RETAIL_PRICE?: NumberRange
	SELLER_CONTACT?: string
	SELLER_INFO?: string
	TITLE_STATUS?: string
	TITLE_NUMBER?: string
	TITLE_TYPE?: string
	TYPE_FIELD_1?: string
	TYPE_FIELD_2?: string
	TYPE_FIELD_3?: string
	TYPE_FIELD_4?: string
	QA_FIELDS?: Record<string, string>
}

function getApiFilter(stateParameters: ParsedStateParameters): VehicleSearchResultsData$input['filter'] {
	const filter: VehicleSearchResultsData$input['filter'] = {}
	for (const parameterKey in stateParameters) {
		if (parameterKey === 'STORE' && stateParameters.STORE) {
			filter.stores = stateParameters.STORE
		} else if (parameterKey === 'INVENTORY_TYPE' && stateParameters.INVENTORY_TYPE) {
			filter.typeIds = [parseInt(stateParameters.INVENTORY_TYPE, 10)]
		} else if (parameterKey === 'LOCATION' && stateParameters.LOCATION) {
			filter.location = stateParameters.LOCATION
		} else if (parameterKey === 'STATUS' && stateParameters.STATUS) {
			filter.statuses = stateParameters.STATUS === '@' ? ['A', 'H'] : [stateParameters.STATUS]
		} else if (parameterKey === 'USER_STATUS' && stateParameters.USER_STATUS) {
			filter.userStatuses = [stateParameters.USER_STATUS]
		} else if (parameterKey === 'CATEGORY' && stateParameters.CATEGORY) {
			filter.category = stateParameters.CATEGORY
		} else if (parameterKey === 'STOCK_NUMBER' && stateParameters.STOCK_NUMBER) {
			filter.stockNumber = stateParameters.STOCK_NUMBER
		} else if (parameterKey === 'VIN' && stateParameters.VIN) {
			filter.vin = stateParameters.VIN
		} else if (parameterKey === 'ADJUSTER' && stateParameters.ADJUSTER) {
			filter.adjuster = stateParameters.ADJUSTER
		} else if (parameterKey === 'BODY_STYLE' && stateParameters.BODY_STYLE) {
			filter.bodyStyle = stateParameters.BODY_STYLE
		} else if (parameterKey === 'CLAIM_NUMBER' && stateParameters.CLAIM_NUMBER) {
			filter.claimNumber = stateParameters.CLAIM_NUMBER
		} else if (parameterKey === 'JUNKING_NUMBER' && stateParameters.JUNKING_NUMBER) {
			filter.junkingNumber = stateParameters.JUNKING_NUMBER
		} else if (parameterKey === 'FILE_NUMBER' && stateParameters.FILE_NUMBER) {
			filter.fileNumber = stateParameters.FILE_NUMBER
		} else if (parameterKey === 'EXTERNAL_COLOR' && stateParameters.EXTERNAL_COLOR) {
			filter.externalColor = stateParameters.EXTERNAL_COLOR
		} else if (parameterKey === 'ENTERED_BY_USER' && stateParameters.ENTERED_BY_USER) {
			filter.enteredByUserId = stateParameters.ENTERED_BY_USER
		} else if (parameterKey === 'DESCRIPTION' && stateParameters.DESCRIPTION) {
			filter.description = stateParameters.DESCRIPTION
		} else if (parameterKey === 'DISMANTLER' && stateParameters.DISMANTLER) {
			filter.dismantlerId = stateParameters.DISMANTLER
		} else if (parameterKey === 'DISMANTLED' && typeof stateParameters.DISMANTLED === 'boolean') {
			filter.dismantled = stateParameters.DISMANTLED
		} else if ((parameterKey === 'DATE_ENTERED' && stateParameters.DATE_ENTERED?.from) || stateParameters.DATE_ENTERED?.to) {
			filter.dateEntered = {}
			if (stateParameters.DATE_ENTERED?.from) {
				filter.dateEntered.gte = parseISO(stateParameters.DATE_ENTERED?.from)
			}
			if (stateParameters.DATE_ENTERED?.to) {
				filter.dateEntered.lte = parseISO(stateParameters.DATE_ENTERED?.to)
			}
		} else if (parameterKey === 'PICKUP_LOCATION' && stateParameters.PICKUP_LOCATION) {
			filter.pickupLocation = stateParameters.PICKUP_LOCATION
		} else if (parameterKey === 'PURCHASED_FROM' && stateParameters.PURCHASED_FROM) {
			filter.purchasedFrom = stateParameters.PURCHASED_FROM
		} else if (parameterKey === 'RETAIL_PRICE' && (stateParameters.RETAIL_PRICE?.from || stateParameters.RETAIL_PRICE?.to)) {
			filter.retailPrice = {}
			if (stateParameters.RETAIL_PRICE?.from) {
				filter.retailPrice.gte = stateParameters.RETAIL_PRICE?.from.toString()
			}
			if (stateParameters.RETAIL_PRICE?.to) {
				filter.retailPrice.lte = stateParameters.RETAIL_PRICE?.to.toString()
			}
		} else if (parameterKey === 'SELLER_CONTACT' && stateParameters.SELLER_CONTACT) {
			filter.sellerContact = stateParameters.SELLER_CONTACT
		} else if (parameterKey === 'SELLER_INFO' && stateParameters.SELLER_INFO) {
			filter.sellerInfo = stateParameters.SELLER_INFO
		} else if (parameterKey === 'TITLE_STATUS' && stateParameters.TITLE_STATUS) {
			filter.titleStatus = stateParameters.TITLE_STATUS
		} else if (parameterKey === 'TITLE_NUMBER' && stateParameters.TITLE_NUMBER) {
			filter.titleNumber = stateParameters.TITLE_NUMBER
		} else if (parameterKey === 'TITLE_TYPE' && stateParameters.TITLE_TYPE) {
			filter.titleType = stateParameters.TITLE_TYPE
		} else if ((parameterKey === 'YEAR_RANGE' && stateParameters.YEAR_RANGE?.from) || stateParameters.YEAR_RANGE?.to) {
			filter.year = {}
			if (stateParameters.YEAR_RANGE?.from) {
				filter.year.gte = stateParameters.YEAR_RANGE?.from
			}
			if (stateParameters.YEAR_RANGE?.to) {
				filter.year.lte = stateParameters.YEAR_RANGE?.to
			}
		} else if (parameterKey === 'VEHICLE_MANUFACTURER' && stateParameters.VEHICLE_MANUFACTURER) {
			filter.manufacturerId = parseInt(stateParameters.VEHICLE_MANUFACTURER, 10)
		} else if (parameterKey === 'VEHICLE_MODEL' && stateParameters.VEHICLE_MODEL) {
			filter.modelId = parseInt(stateParameters.VEHICLE_MODEL, 10)
		} else if (parameterKey === 'TYPE_FIELD_1' && stateParameters.TYPE_FIELD_1) {
			filter.typeField1 = stateParameters.TYPE_FIELD_1
		} else if (parameterKey === 'TYPE_FIELD_2' && stateParameters.TYPE_FIELD_2) {
			filter.typeField2 = stateParameters.TYPE_FIELD_2
		} else if (parameterKey === 'TYPE_FIELD_3' && stateParameters.TYPE_FIELD_3) {
			filter.typeField3 = stateParameters.TYPE_FIELD_3
		} else if (parameterKey === 'TYPE_FIELD_4' && stateParameters.TYPE_FIELD_4) {
			filter.typeField4 = stateParameters.TYPE_FIELD_4
		} else if (parameterKey === 'QA_FIELDS' && stateParameters.QA_FIELDS && Object.keys(stateParameters.QA_FIELDS).length) {
			filter.customFields = Object.entries(stateParameters.QA_FIELDS).map(([key, value]) => {
				return {
					name: key,
					value: buildInventoryQuestionValueFilterArgument(value),
				}
			})
		}
	}

	return filter
}

export default function ({ stateRouter }: AppContext) {
	stateRouter.addState({
		name: 'app.vehicle-search.results',
		route: 'results',
		querystringParameters: [
			'selectedVehicleId',
			'pageNumber',
			'perPageCount',
			'sortColumn',
			'sortDirection',
			'STORE',
			'INVENTORY_TYPE',
			'LOCATION',
			'STATUS',
			'USER_STATUS',
			'CATEGORY',
			'YEAR_RANGE',
			'VEHICLE_MANUFACTURER',
			'VEHICLE_MODEL',
			'STOCK_NUMBER',
			'VIN',
			'ADJUSTER',
			'BODY_STYLE',
			'CLAIM_NUMBER',
			'JUNKING_NUMBER',
			'FILE_NUMBER',
			'EXTERNAL_COLOR',
			'ENTERED_BY_USER',
			'DESCRIPTION',
			'DISMANTLER',
			'DISMANTLED',
			'DATE_ENTERED',
			'PICKUP_LOCATION',
			'PURCHASED_FROM',
			'RETAIL_PRICE',
			'SELLER_CONTACT',
			'SELLER_INFO',
			'TITLE_STATUS',
			'TITLE_NUMBER',
			'TITLE_TYPE',
			'TYPE_FIELD_1',
			'TYPE_FIELD_2',
			'TYPE_FIELD_3',
			'TYPE_FIELD_4',
			'QA_FIELDS',
		],
		defaultParameters: {
			selectedVehicleId: null,
			pageNumber: '1',
			perPageCount: (): PerPageCount => {
				const perPageCount = localStorage.getItem('vehicleSearchResultsPerPageCount') ?? ''
				return perPageCount && isValidPerPageCount(perPageCount) ? perPageCount : '10' //Fallback to a valid default
			},
			STORE: `[${getSession()?.currentStore}]`,
		},
		template: {
			svelte: true,
			component: Results,
		},
		async resolve(_data, parameters: Record<string, string>) {
			const parsedParameters: ParsedStateParameters = {}
			for (const key in parameters) {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-argument
				parsedParameters[key] = jsonParseParameters.has(key) ? JSON.parse(parameters[key]) : parameters[key]
			}
			const filter = getApiFilter(parsedParameters)
			const variables: VehicleSearchResultsData$input = {
				filter,
				pagination: {
					pageSize: parsedParameters.perPageCount && isValidPerPageCount(parsedParameters?.perPageCount?.toString?.() ?? '') ? parsedParameters.perPageCount : 10,
					pageNumber: parsedParameters.pageNumber ?? 1,
				},
			}
			const sortDirection = parsedParameters.sortDirection ?? 'ASC'
			const orderBy = getOrderBy(parsedParameters.sortColumn, sortDirection)
			if (isValidOrderBy(orderBy)) {
				variables.orderBy = [orderBy]
			}
			const { data } = await dataQuery.fetch({ variables })
			return {
				pageNumber: parseInt(parameters.pageNumber, 10),
				perPageCount: parameters.perPageCount,
				sortDirection,
				sortColumnProp: parsedParameters?.sortColumn,
				previousSortDirection: sortDirection === 'ASC' ? 'DESC' : 'ASC',
				vehicleSearchResults: data?.vehicles ?? [],
				unitTypeId: parsedParameters.INVENTORY_TYPE,
			}
		},
	})
}
