<script lang="ts">
	import type { InventoryAllocations$result } from '$houdini'
	type Allocations = InventoryAllocations$result['inventory']['allocations']

	import { Table, Td } from '@isoftdata/svelte-table'
	import { graphql } from '$houdini'
	import Icon from '@isoftdata/svelte-icon'
	import financialNumber from 'financial-number'

	interface AllocationRow {
		storeId: number
		document: 'Sales Order' | 'Job' | 'Transfer Order'
		documentId: number
		destination: string
		quantity: string
		dateEntered: Date
		expiration: string | Date
		salesperson: string
	}

	const allocationsQuery = graphql(`
		query InventoryAllocations($inventoryId: UInt!) {
			inventory(id: $inventoryId) {
				allocations {
					salesOrderLines {
						id
						description
						quantity
						salesOrder {
							id
							date
							expirationDate
							store {
								id
							}
							documentType {
								id
								name
							}
							salesperson {
								name
								firstName
								lastName
							}
							shippingAddress {
								company
							}
						}
					}
					jobParts {
						id
						quantity
						job {
							id
							workOrder {
								id
								date
								address {
									company
								}
								store {
									id
									name
								}
								user {
									name
								}
							}
						}
					}
					transferOrderLines {
						id
						quantity
						quantityReceived
						transferOrder {
							id
							date
							store {
								id
							}
							destinationStore {
								id
								name
							}
							user {
								firstName
								lastName
							}
						}
					}
				}
			}
		}
	`)

	export let inventoryId: number

	let allocations: Allocations | Promise<Allocations | undefined> | undefined = undefined
	let allocationRows: AllocationRow[] = []

	async function loadAllocations(inventoryId: number) {
		return inventoryId ? (await allocationsQuery.fetch({ variables: { inventoryId }, policy: 'CacheOrNetwork' }))?.data?.inventory?.allocations ?? undefined : undefined
	}

	function loadAndSetAllocations(inventoryId: number) {
		allocations = loadAllocations(inventoryId)
		allocations.then(allocations => {
			allocationRows = allocations ? computeAllocationRows(allocations) : []
		})
	}

	$: loadAndSetAllocations(inventoryId)

	function computeAllocationRows(allocations: Allocations) {
		let rows: AllocationRow[] = []
		for (const salesOrderLine of allocations.salesOrderLines) {
			const salesperson = `${salesOrderLine.salesOrder.salesperson?.firstName} ${salesOrderLine.salesOrder.salesperson?.lastName}`
			rows.push({
				storeId: salesOrderLine.salesOrder.store.id,
				document: 'Sales Order',
				documentId: salesOrderLine.salesOrder.id,
				destination: salesOrderLine.salesOrder?.shippingAddress?.company ?? 'Unknown',
				quantity: parseFloat(salesOrderLine.quantity).toString(),
				dateEntered: salesOrderLine.salesOrder.date,
				expiration: salesOrderLine.salesOrder.expirationDate ? salesOrderLine.salesOrder.expirationDate : '',
				salesperson: salesperson.trim() || 'Unknown',
			})
		}
		for (const jobPart of allocations.jobParts) {
			rows.push({
				storeId: jobPart.job.workOrder.store.id,
				document: 'Job',
				documentId: jobPart.job.id,
				destination: jobPart.job.workOrder.address?.company ?? 'Unknown',
				quantity: parseFloat(jobPart.quantity).toString(),
				dateEntered: jobPart.job.workOrder.date,
				expiration: 'N/A',
				salesperson: jobPart.job.workOrder.user.name,
			})
		}
		for (const transferOrderLine of allocations.transferOrderLines) {
			rows.push({
				storeId: transferOrderLine.transferOrder.store.id,
				document: 'Transfer Order',
				documentId: transferOrderLine.transferOrder.id,
				destination: transferOrderLine.transferOrder.destinationStore.name,
				quantity: parseFloat(
					financialNumber(transferOrderLine.quantity || '0')
						.minus(transferOrderLine.quantityReceived || '0')
						.toString(),
				).toString(),
				dateEntered: transferOrderLine.transferOrder.date,
				expiration: 'N/A',
				salesperson: `${transferOrderLine.transferOrder.user.firstName} ${transferOrderLine.transferOrder.user.lastName}`,
			})
		}
		return rows
	}
</script>

{#await allocations}
	<Icon
		icon="spinner"
		class="fa-xl fa-spin"
	/>
{:then}
	<Table
		rows={allocationRows}
		columns={[
			{ property: 'storeId', name: 'Store #', title: 'The store that is allocating this item' },
			{ property: 'document', name: 'Document', title: 'The document type being used to allocate this item' },
			{ property: 'documentId', name: 'Document #', title: 'The ID of the document being used to allocate this item' },
			{ property: 'destination', name: 'Destination', title: 'The shipping destination for the order allocating this item' },
			{ property: 'quantity', name: 'Quantity', title: 'The quantity being sold' },
			{ property: 'dateEntered', name: 'Date', title: 'The date of the allocating document' },
			{ property: 'expiration', name: 'Expiration', title: 'The date this document expires and releases this allocation (if applicable)' },
			{ property: 'salesperson', name: 'Salesperson', title: 'The assigned salesperson for this order' },
		]}
		responsive
		let:row={allocation}
	>
		{@const { storeId, document, documentId, destination, quantity, dateEntered, expiration, salesperson } = allocation}
		<tr>
			<Td property="storeId">{storeId}</Td>
			<Td property="document">{document}</Td>
			<Td property="documentId">{documentId}</Td>
			<Td property="destination">{destination}</Td>
			<Td property="quantity">{quantity}</Td>
			<Td property="dateEntered">{dateEntered.toLocaleDateString()}</Td>
			<Td property="expiration">{expiration instanceof Date ? expiration.toLocaleDateString() : expiration}</Td>
			<Td property="salesperson">{salesperson}</Td>
		</tr>
	</Table>
{:catch error}
	<p>Error loading part allocations.</p>
{/await}
