import makeItModalComponent from '@isoftdata/modal'
import makeItTableComponent from '@isoftdata/table'
import makeItInput from '@isoftdata/input'
import makeItCurrencyInput from '@isoftdata/currency-input'
import makeItSelect from '@isoftdata/select'
import makeItCheckbox from '@isoftdata/checkbox'
import makeItButton from '@isoftdata/button'
import { klona } from 'klona'
import { booleanToYesNo as yesNo } from '@isoftdata/utility-string'
import makeNavTabBar from '@isoftdata/nav-tab-bar-component'
import pProps from 'p-props'
import template from './inventory-type.html'

const inventoryTypeFields = `#graphql
active
id
requireSerialization
name
setId
vehicle
#pickable
#publicAllowed
`

const defaultInventoryTypeModalState = Object.freeze({
	shown: false,
	title: '',
	inventoryTypeItem: {},
	isNew: true,
	isSaving: false,
})

export default function({ mediator, stateRouter }) {
	const stateName = 'app.configuration.inventory-type'

	stateRouter.addState({
		name: stateName,
		route: 'inventory-type',
		querystringParameters: [ 'inventoryTypeId' ],
		defaultChild: 'custom-question',
		template: {
			template,
			components: {
				itTable: makeItTableComponent({ stickyHeader: true }),
				itInput: makeItInput({ twoway: true, lazy: false }),
				itSelect: makeItSelect({ twoway: true, lazy: false }),
				itModal: makeItModalComponent(),
				itCheckbox: makeItCheckbox(),
				itCurrencyInput: makeItCurrencyInput({ twoway: true }),
				itButton: makeItButton(),
				navTabBar: makeNavTabBar(stateRouter),
			},
			computed: {
				selectedInventoryType() {
					const selectedInventoryTypeId = this.get('selectedInventoryTypeId')

					return this.get('inventoryTypes')
						.find(({ id }) => id === selectedInventoryTypeId) || {}
				},
				typeSets() {
					const inventoryTypes = this.get('inventoryTypes')
					let inventoryTypeSets = new Map()

					for (const inventoryType of inventoryTypes) {
						if (inventoryType.id === inventoryType.setId && !inventoryTypeSets.has(inventoryType.setId)) {
							inventoryTypeSets.set(inventoryType.setId, inventoryType)
						}
					}

					return Array.from(inventoryTypeSets.keys()).map(setId => {
						return { setId, name: inventoryTypeSets.get(setId)?.name }
					})
				},
				childStates() {
					const inventoryType = this.get('selectedInventoryType')
					let stateParameters = {}

					if (inventoryType && inventoryType.id) {
						stateParameters = {
							inventoryTypeId: inventoryType.id,
							typeSetId: inventoryType.id,
						}
					}

					return [
						{
							title: 'Custom Questions',
							stateName: 'app.configuration.inventory-type.custom-question',
							stateParameters,
						},
					]
				},
				hasSelectedInventoryType() {
					const type = this.get('selectedInventoryType')
					return !!(type && type.id)
				},
				displayInventoryTypes() {
					const res = this.get('inventoryTypes')
						.map(inventoryType => {
							return {
								...inventoryType,
								active: yesNo(inventoryType.active),
								vehicle: yesNo(inventoryType.vehicle),
							}
						})

					return res
				},
				newInventoryType() {
					const inventoryTypeIds = this.get('inventoryTypes')
						.map(inventoryType => inventoryType.id)

					const newInventoryTypeId = Math.max.apply(Math, inventoryTypeIds) + 1

					return {
						id: newInventoryTypeId,
						setId: newInventoryTypeId,
						name: '',
						vehicle: true,
						active: true,
						//publicAllowed: true,
						//pickable: true,
					}
				},
			},
			getInvetoryModalTitle(inventoryTypeModal) {
				const { isNew } = inventoryTypeModal

				return `${isNew ? 'New' : 'Edit'} Inventory Type`
			},
			async editInventoryType(inventoryType) {
				const inventoryTypeItem = klona(inventoryType || this.get('newInventoryType'))

				await this.set('inventoryTypeModal', {
					...klona(defaultInventoryTypeModalState),
					shown: true,
					inventoryTypeItem,
					isNew: !inventoryType,
				})
				this.find('#partTypeNameInput')?.select?.()
			},
			inventoryTypeClicked(inventoryType) {
				const { name: currentState } = stateRouter.getActiveState()

				stateRouter.go(currentState === stateName ? `${stateName}.custom-question` : null, { inventoryTypeId: inventoryType?.id !== this.get('selectedInventoryTypeId') ? inventoryType.id : null })
			},
			async saveInventoryType(inventoryType, isNew = true) {
				const ractive = this

				if (ractive.get('inventoryTypes').find(({ name, id }) => name === inventoryType.name && id !== inventoryType.id)) {
					return alert(`Inventory Type "${inventoryType.name}" already exists. Please choose a unique name.`)
				}

				const updateMutation = `#graphql
				mutation UpdateInventoryType($input: InventoryTypeUpdate!) {
					updateInventoryType(input: $input) {
						${inventoryTypeFields}
					}
				}`

				const createMutation = `#graphql
				mutation CreateInventoryType($input: InventoryTypeCreate!) {
					createInventoryType(input: $input) {
						${inventoryTypeFields}
					}
				}`

				ractive.set({ 'inventoryTypeModal.isSaving': true })

				try {
					await mediator.publish('graphqlFetch', isNew ? createMutation : updateMutation, {
						input: inventoryType,
					})

					ractive.upsert('inventoryTypes', 'id', inventoryType)
					ractive.set({ 'inventoryTypeModal.shown': false })
				} catch (err) {
					mediator.publish('showMessage', { heading: 'Error Saving Inventory Type', message: err.message, type: 'danger', time: false })
				} finally {
					ractive.set({ 'inventoryTypeModal.isSaving': false })
				}
			},
		},
		async resolve(data, { inventoryTypeId }) {
			const { inventoryTypes } = await mediator.publish('graphqlFetch', `#graphql
				query InventoryTypes {
					inventoryTypes {
						${inventoryTypeFields}
					}
				}
			`)

			return await pProps({
				inventoryTypes,
				selectedInventoryTypeId: inventoryTypeId ? parseInt(inventoryTypeId, 10) : undefined,
				columns: [
					{ property: 'active', name: 'Active' },
					{ property: 'id', name: 'Type #', align: 'right' },
					{ property: 'setId', name: 'Set #', align: 'right' },
					{ property: 'name', name: 'Name' },
					{ property: 'vehicle', name: 'Vehicle Unit' },
				],
			})
		},
		activate({ domApi: ractive }) {
			const inventoryType = ractive.get('selectedInventoryType')
			if (inventoryType && inventoryType.inventoryTypeId) {
				ractive.find(`#inventory_type_id_${inventoryType.inventoryTypeId}`)?.scrollIntoView({
					block: 'center',
					inline: 'center',
					behavior: 'smooth',
				})
				/* ractive.findComponent('itTable').setPageVisibleByItemId({
					id: inventoryType.inventoryTypeId,
					keyName: 'inventoryTypeId',
				}) */
			}
		},
	})
}
