import { graphql } from '$houdini'

const enteredBy = `#graphql
enteredBy {
	id
	name
}`

export const shippingDimensions = `#graphql
shippingDimensions {
	weightUnit
	measurementUnit
	weight
	length
	width
	height
}`

export const defaultShippingDimensions = `#graphql
defaultShippingDimensions {
	weightUnit
	measurementUnit
	weight
	width
	length
	height
}`

const inventoryOptionValuesPartial = `#graphql
inventoryOptions: optionValues {
    inventoryOptionValueId: id
    optionId
    value
    option {
        id
        name
        defaultChoice
		required
        rank
		dataType
		public
        manufacturer {
    		id
        	name
        }
        model {
        	id
        	name
        }
        category {
        	id
        	name
        }
		inventoryType {
			id
		}
        choices {
        	default
        	label
        	rank
        }
    }
}`

export const inventoryReturnData = `#graphql
	innodbInventoryid: id
	attachments {
		fileId: id
		file {
			id
			createdDate: created
			extension
			mimeType: mimetype
			name
			path: url
			size
			type
			updated
		}
		public
		rank
	}
	averageCoreCost
	averageCost
	averageDemandPerDay
	averageDemandPerMonth
	bodyStyle
	buyPackage
	category {
		id
		description
		name
	}
	condition
	coreClass
	coreCost
	coreRequired
	coreRequiredToVendor
	cost
	cost
	dateEntered
	dateModified
	dateViewed
	daysToReturn
	daysToReturnCore
	daysToReturnCoreToVendor
	daysToReturnToVendor
	defaultVendor {
		id
		companyName
		contactName
		coreClassHistory
	}
	deplete
	description
	distributorCorePrice
	distributorPrice
	${enteredBy}
	freezeUntil
	glCategory {
		id
		name
	}
	inventoryId
	${inventoryOptionValuesPartial}
	inventoryTypeId
	jobberCorePrice
	jobberPrice
	jobberPrice
	interchangeNumber
	inventoryType {
		typeData1History
		typeData2History
		typeData3History
		typeData4History
		setId
		id
	}
	listPrice
	locations {
		id
		holdQuantity
		location {
			description
			allowInventory
		}
		locationId
		name
		permanent
		quantity
		rank
	}
	manufacturer {
		id
		name
	}
	manufacturerId
	maxQuantity
	minQuantity
	model {
		id
		name
	}
	modelId
	notes
	oemNumber
	parentModel {
		id
		name
	}
	parentManufacturer {
		id
		name
	}
	partNumber
	popularityCode
	printTag
	public
	quantity
	quantityAvailable
	quantityOnHold
	replenishable
	retailCorePrice
	retailCorePrice
	retailPrice
	retailPrice
	returnable
	returnableToVendor
	saleClass {
		code
	}
	serials(filter: $serialFilter) {
		id
		dateEntered
		enteredOnDocumentId
		enteredOnDocumentLineId
		enteredOnDocumentStoreId
		enteredOnDocumentType
		${inventoryOptionValuesPartial}
		location {
			... on Location {
				id
				name
				description
			}
			... on VirtualLocation {
				name
			}
		}
		number
		status
		usedOnDocumentId
		usedOnDocumentStoreId
		usedOnDocumentType
	}
	safetyStockPercent
	seasonal
	sellPackage
	serialized
	side
	singleQuantity
	${shippingDimensions}
	sku
	status
	stockCategory
	stockingDays
	stockMethod
	stockType
	storeId
	storeId
	suggestedMaxQuantity
	suggestedMinQuantity
	suggestedSafetyStockPercent
	tagNumber
	taxable
	typeField1 {
		label
		data
	}
	typeField2 {
		label
		data
	}
	typeField3 {
		label
		data
	}
	typeField4 {
		label
		data
	}
	userStatus
	useVendorOrderMultiplier
	vehicle {
		make
		model
		vin
		year
		bodyStyle
		stockNumber
		topImage {
		url
		name
		}
	}
	vehicleId
	vehicleMake
	vehicleModel
	vehicleVin
	vehicleYear
	vendorLeadTime
	vendorPopularityCode
	vendorProductCode
	wholesaleCorePrice
	wholesalePrice
	wholesalePrice
`
export const vehicleInventoryReturnData = `#graphql
innodbInventoryid: id
			inventoryId
			storeId
			tagNumber
			inventoryTypeId
			cost
			wholesalePrice
			retailPrice
			jobberPrice
			retailCorePrice
			side
			status
			glCategoryId
			description
			notes
			quantity
			dateEntered
			dateModified
			vehicleId
			vehicleVin
			vehicleMake
			vehicleModel
			vehicleYear
			oemNumber
			condition
			userStatus
			${shippingDimensions}
			parentModel {
				id
				name
			}
			parentManufacturer {
				id
				name
				models {
					name
					modelId:id
					inventoryTypeId
					active
				}
			}
			model {
				id
				name
			}
			manufacturer {
				id
				name
				models {
					name
					modelId:id
					inventoryTypeId
					active
					${defaultShippingDimensions}
				}
			}
			category {
				id
				name
				description
			}
			attachments {
				fileId: id
				rank
				public
				file {
					id
					name
					path: url
					type
					size
					mimeType: mimetype
					extension
					updated
					createdDate: created
				}
			}
			typeField1 { label data }
			typeField2 { label data }
			typeField3 { label data }
			typeField4 { label data }
			public
			locations {
				id
				name
				rank
				quantity
				holdQuantity
				permanent
			}
			serialized
			serials {
				id
				dateEntered
				number
				location{
					... on Location	{
						id
						name
					}
					... on VirtualLocation {
						name
					}
				}
			}`

graphql(`
fragment ShippingDimensions on Inventory {
	shippingDimensions {
	weightUnit
	measurementUnit
	weight
	length
	width
	height
}
}

`)

graphql(`
fragment InventoryOptionValueData on InventoryOptionValue {
    inventoryOptionValueId: id
    optionId
    value
    option {
        id
        name
        defaultChoice
		required
        rank
		dataType
		public
        manufacturer {
    		id
        	name
        }
        model {
        	id
        	name
        }
        category {
        	id
        	name
        }
		inventoryType {
			id
		}
        choices {
        	default
        	label
        	rank
        }
    }

}
`)

graphql(`
fragment InventoryOptionValues on Inventory {
	inventoryOptions: optionValues {
		...InventoryOptionValueData
	}
}
`)

graphql(`
	fragment SerialOptionValues on InventorySerial {
		inventoryOptions: optionValues {
			...InventoryOptionValueData
		}
	}
`)

graphql(`
fragment EnteredBy on Inventory {
	enteredBy {
		id
		name
	}
}
	`)

graphql(`
fragment InventoryData on Inventory @arguments(serialFilter: { type: "InventorySerialFilter"}) {
	innodbInventoryid: id
	topImage {
		path
		name
	}
	attachments {
		fileId: id
		file {
			id
			createdDate: created
			extension
			mimeType: mimetype
			name
			path: url
			size
			type
			updated
		}
		public
		rank
	}
	averageCoreCost
	averageCost
	averageDemandPerDay
	averageDemandPerMonth
	bodyStyle
	buyPackage
	category {
		id
		description
		name
	}
	condition
	coreClass
	coreCost
	coreRequired
	coreRequiredToVendor
	cost
	cost
	dateEntered
	dateModified
	dateViewed
	daysToReturn
	daysToReturnCore
	daysToReturnCoreToVendor
	daysToReturnToVendor
	defaultVendor {
		id
		code
		companyName
		contactName
		coreClassHistory
	}
	deplete
	description
	distributorCorePrice
	distributorPrice
	...EnteredBy
	freezeUntil
	glCategory {
		id
		name
	}
	inventoryId
	...InventoryOptionValues
	inventoryTypeId
	jobberCorePrice
	jobberPrice
	jobberPrice
	interchangeNumber
	inventoryType {
		typeData1History
		typeData2History
		typeData3History
		typeData4History
		setId
		id
	}
	listPrice
	locations {
		id
		holdQuantity
		location {
			name
			description
			allowInventory
		}
		locationId
		permanent
		quantity
		rank
	}
	manufacturer {
		id
		name
		code
		active
	}
	maxQuantity
	minQuantity
	model {
		id
		name
		active
	}
	notes
	oemNumber
	parentModel {
		id
		name
		active
	}
	parentManufacturer {
		id
		name
		code
		active
	}
	partNumber
	popularityCode
	printTag
	public
	quantity
	quantityAvailable
	quantityOnHold
	replenishable
	retailCorePrice
	retailCorePrice
	retailPrice
	retailPrice
	returnable
	returnableToVendor
	saleClass {
		code
		name
	}
	serials(filter: $serialFilter) {
		id
		dateEntered
		enteredOnDocumentId
		enteredOnDocumentLineId
		enteredOnDocumentStoreId
		enteredOnDocumentType
		...SerialOptionValues
		location {
			... on Location {
				id
				name
				description
			}
			... on VirtualLocation {
				name
			}
		}
		number
		status
		usedOnDocumentId
		usedOnDocumentStoreId
		usedOnDocumentType
	}
	safetyStockPercent
	seasonal
	sellPackage
	serialized
	side
	singleQuantity
	...ShippingDimensions
	sku
	status
	stockCategory
	stockingDays
	stockMethod
	stockType
	storeId
	storeId
	suggestedMaxQuantity
	suggestedMinQuantity
	suggestedSafetyStockPercent
	tagNumber
	taxable
	typeField1 {
		label
		data
	}
	typeField2 {
		label
		data
	}
	typeField3 {
		label
		data
	}
	typeField4 {
		label
		data
	}
	userStatus
	useVendorOrderMultiplier
	vehicle {
		make
		model
		vin
		year
		bodyStyle
		stockNumber
		topImage {
		url
		name
		}
	}
	vehicleId
	vehicleMake
	vehicleModel
	vehicleVin
	vehicleYear
	vendorLeadTime
	vendorPopularityCode
	vendorProductCode
	wholesaleCorePrice
	wholesalePrice
	wholesalePrice
}
`)
