export const ActionEnum = {
    "CREATE": "CREATE",
    "DELETE": "DELETE",
    "UPDATE": "UPDATE"
};

export const AddressSearchStatusCodeEnum = {
    "None": "None",
    "Partial": "Partial",
    "Verified": "Verified"
};

export const AddressServiceEnum = {
    "MOCK": "MOCK",
    "NONE": "NONE",
    "TAXJAR": "TAXJAR",
    "TAXJAR_SANDBOX": "TAXJAR_SANDBOX",
    "VERTEX": "VERTEX"
};

export const AdjustmentTypeScopeEnum = {
    "BOTH": "BOTH",
    "DOCUMENT": "DOCUMENT",
    "LINE": "LINE"
};

export const AppraisalCodeEnum = {
    "ALL_AUCTIONS": "ALL_AUCTIONS",
    "CUSTOM": "CUSTOM",
    "HTP_LIVE": "HTP_LIVE",
    "LAST_AUCTION": "LAST_AUCTION",
    "LAST_QUARTER": "LAST_QUARTER",
    "LAST_YEAR": "LAST_YEAR",
    "PANDEMIC": "PANDEMIC"
};

export const DashboardReportShareType = {
    "EVERYONE": "EVERYONE",
    "GROUP": "GROUP",
    "STORE": "STORE",
    "USER": "USER"
};

export const DataTypeEnum = {
    "AUTOSUGGEST_TEXT": "AUTOSUGGEST_TEXT",
    "BOOLEAN": "BOOLEAN",
    "CHOICE": "CHOICE",
    "CURRENCY": "CURRENCY",
    "DATE": "DATE",
    "DATETIME": "DATETIME",
    "NUMBER": "NUMBER",
    "TEXT": "TEXT",
    "TIME": "TIME"
};

export const DimensionsMeasurementUnit = {
    "CM": "CM",
    "FT": "FT",
    "IN": "IN",
    "M": "M"
};

export const DimensionsWeightUnit = {
    "G": "G",
    "KG": "KG",
    "LB": "LB",
    "MG": "MG",
    "N": "N",
    "OZ": "OZ",
    "TON": "TON"
};

export const DocumentTypeEnum = {
    "NONE": "NONE",
    "PAYMENT": "PAYMENT",
    "PURCHASE_ORDER": "PURCHASE_ORDER",
    "PURCHASE_ORDER_LINE": "PURCHASE_ORDER_LINE",
    "SALES_ORDER": "SALES_ORDER",
    "SALES_ORDER_LINE": "SALES_ORDER_LINE",
    "TRANSFER_ORDER": "TRANSFER_ORDER",
    "TRANSFER_ORDER_LINE": "TRANSFER_ORDER_LINE",
    "WORK_ORDER": "WORK_ORDER",
    "WORK_ORDER_PART": "WORK_ORDER_PART"
};

export const FileType = {
    "Executable": "Executable",
    "Image": "Image",
    "PDF": "PDF",
    "Report": "Report",
    "Text": "Text",
    "Unknown": "Unknown"
};

export const GlAccountTypeEnum = {
    "ASSET": "ASSET",
    "EQUITY": "EQUITY",
    "EXPENSE": "EXPENSE",
    "LIABILITY": "LIABILITY",
    "REVENUE": "REVENUE"
};

export const InventoryFieldSearchModeEnum = {
    "AUTO_WILDCARD": "AUTO_WILDCARD",
    "STANDARD": "STANDARD"
};

export const InventoryFieldTypeEnum = {
    "BOOLEAN": "BOOLEAN",
    "CHOICE": "CHOICE",
    "CURRENCY": "CURRENCY",
    "DATE": "DATE",
    "DATETIME": "DATETIME",
    "FLOAT": "FLOAT",
    "ID": "ID",
    "INT": "INT",
    "TEXT": "TEXT",
    "UINT": "UINT"
};

export const InventoryReplicationResult = {
    /**
     * Inventory has already been replicated.
    */
    "DUPLICATE": "DUPLICATE",

    /**
     * Inventory was not replicated.
    */
    "FAILURE": "FAILURE",

    /**
     * Inventory was successfully replicated.
    */
    "SUCCESS": "SUCCESS"
};

export const InventorySearchStatus = {
    /**
     * Available
    */
    "A": "A",

    /**
     * Bidding
    */
    "B": "B",

    /**
     * Crushed
    */
    "C": "C",

    /**
     * Deleted
    */
    "D": "D",

    /**
     * Hold
    */
    "H": "H",

    /**
     * Sold
    */
    "S": "S",

    /**
     * Transferred
    */
    "T": "T"
};

export const InventorySort = {
    "attachmentCount_ASC": "attachmentCount_ASC",
    "attachmentCount_DESC": "attachmentCount_DESC",
    "bodyStyle_ASC": "bodyStyle_ASC",
    "bodyStyle_DESC": "bodyStyle_DESC",
    "category_ASC": "category_ASC",
    "category_DESC": "category_DESC",
    "dateEntered_ASC": "dateEntered_ASC",
    "dateEntered_DESC": "dateEntered_DESC",
    "dateModified_ASC": "dateModified_ASC",
    "dateModified_DESC": "dateModified_DESC",
    "description_ASC": "description_ASC",
    "description_DESC": "description_DESC",
    "id_ASC": "id_ASC",
    "id_DESC": "id_DESC",
    "inventoryTypeId_ASC": "inventoryTypeId_ASC",
    "inventoryTypeId_DESC": "inventoryTypeId_DESC",
    "inventoryTypeName_ASC": "inventoryTypeName_ASC",
    "inventoryTypeName_DESC": "inventoryTypeName_DESC",
    "manufacturerName_ASC": "manufacturerName_ASC",
    "manufacturerName_DESC": "manufacturerName_DESC",
    "modelName_ASC": "modelName_ASC",
    "modelName_DESC": "modelName_DESC",
    "notes_ASC": "notes_ASC",
    "notes_DESC": "notes_DESC",
    "oemNumber_ASC": "oemNumber_ASC",
    "oemNumber_DESC": "oemNumber_DESC",
    "parentManufacturerName_ASC": "parentManufacturerName_ASC",
    "parentManufacturerName_DESC": "parentManufacturerName_DESC",
    "parentModelName_ASC": "parentModelName_ASC",
    "parentModelName_DESC": "parentModelName_DESC",
    "partNumber_ASC": "partNumber_ASC",
    "partNumber_DESC": "partNumber_DESC",
    "quantity_ASC": "quantity_ASC",
    "quantity_DESC": "quantity_DESC",
    "retailCorePrice_ASC": "retailCorePrice_ASC",
    "retailCorePrice_DESC": "retailCorePrice_DESC",
    "retailPrice_ASC": "retailPrice_ASC",
    "retailPrice_DESC": "retailPrice_DESC",
    "side_ASC": "side_ASC",
    "side_DESC": "side_DESC",
    "status_ASC": "status_ASC",
    "status_DESC": "status_DESC",
    "storeId_ASC": "storeId_ASC",
    "storeId_DESC": "storeId_DESC",
    "storeName_ASC": "storeName_ASC",
    "storeName_DESC": "storeName_DESC",
    "tagNumber_ASC": "tagNumber_ASC",
    "tagNumber_DESC": "tagNumber_DESC",
    "typeField1_ASC": "typeField1_ASC",
    "typeField1_DESC": "typeField1_DESC",
    "typeField2_ASC": "typeField2_ASC",
    "typeField2_DESC": "typeField2_DESC",
    "typeField3_ASC": "typeField3_ASC",
    "typeField3_DESC": "typeField3_DESC",
    "typeField4_ASC": "typeField4_ASC",
    "typeField4_DESC": "typeField4_DESC",
    "userStatus_ASC": "userStatus_ASC",
    "userStatus_DESC": "userStatus_DESC",
    "vehicleMake_ASC": "vehicleMake_ASC",
    "vehicleMake_DESC": "vehicleMake_DESC",
    "vehicleModel_ASC": "vehicleModel_ASC",
    "vehicleModel_DESC": "vehicleModel_DESC",
    "vehicleYear_ASC": "vehicleYear_ASC",
    "vehicleYear_DESC": "vehicleYear_DESC",
    "wholesalePrice_ASC": "wholesalePrice_ASC",
    "wholesalePrice_DESC": "wholesalePrice_DESC",
    "year_ASC": "year_ASC",
    "year_DESC": "year_DESC"
};

export const InventoryStatusEnum = {
    "ALLOCATED": "ALLOCATED",
    "AVAILABLE": "AVAILABLE",
    "CONSUMED": "CONSUMED",
    "FOUND": "FOUND",
    "IN_TRANSIT": "IN_TRANSIT",
    "LOST": "LOST",
    "ON_HOLD": "ON_HOLD",
    "ON_ORDER": "ON_ORDER",
    "RETURNED": "RETURNED",
    "SOLD": "SOLD",
    "WORK_IN_PROGRESS": "WORK_IN_PROGRESS"
};

export const MessageDisplay = {
    "BOTH": "BOTH",
    "PRINT": "PRINT",
    "SHOW": "SHOW"
};

export const MessageType = {
    "CUSTOMER": "CUSTOMER",
    "PART": "PART",
    "PART_TYPE": "PART_TYPE",
    "VEHICLE": "VEHICLE",
    "VENDOR": "VENDOR"
};

export const OverTimeTypeEnum = {
    "Daily": "Daily",
    "Weekly": "Weekly"
};

export const PermissionLevel = {
    "Global": "Global",
    "None": "None",
    "Store": "Store"
};

export const PrivateEnum = {
    "FALSE": "FALSE",
    "TRUE": "TRUE"
};

export const PurchaseOrderLineType = {
    "DIRTY_CORE": "DIRTY_CORE",
    "INHERENT_CORE": "INHERENT_CORE",
    "INVENTORY": "INVENTORY",
    "MISC": "MISC"
};

export const Scope = {
    "GLOBAL": "GLOBAL",
    "GROUP": "GROUP",
    "STORE": "STORE",
    "USER": "USER"
};

export const SettingTypeEnum = {
    "ACCESSIBILITY": "ACCESSIBILITY",
    "HIDDEN": "HIDDEN",
    "IMPORTANT_CONFIGURATION": "IMPORTANT_CONFIGURATION",
    "INTERFACE_HISTORY": "INTERFACE_HISTORY",
    "INTERFACE_PREFERENCE": "INTERFACE_PREFERENCE",
    "OPTIONAL_CONFIGURATION": "OPTIONAL_CONFIGURATION",
    "PREFERENCE": "PREFERENCE"
};

export const SideEnum = {
    "BOTH": "BOTH",
    "LEFT": "LEFT",
    "NA": "NA",
    "RIGHT": "RIGHT"
};

export const SkuLookupRelationshipTypeEnum = {
    "ALT_NUMBER": "ALT_NUMBER",
    "ALT_RECORD": "ALT_RECORD",
    "SUPERSESSION": "SUPERSESSION",
    "UPC": "UPC"
};

export const StatusEnum = {
    /**
     * Available
    */
    "A": "A",

    /**
     * Bidding
    */
    "B": "B",

    /**
     * Crushed
    */
    "C": "C",

    /**
     * Deleted
    */
    "D": "D",

    /**
     * Hold
    */
    "H": "H",

    /**
     * Sold
    */
    "S": "S",

    /**
     * Transferred
    */
    "T": "T"
};

export const StockCategory = {
    /**
     * Inventory that is not from a Vehicle and is not replenishable.
    */
    "MISC": "MISC",

    /**
     * Replenishable inventory does not get marked as sold when quantity hits zero. It is expected to be restocked.
    */
    "REPLENISHABLE": "REPLENISHABLE",

    /**
     * Inventory that is from a Vehicle and is not replenishable.
    */
    "STANDARD": "STANDARD"
};

export const StockMethodEnum = {
    "HISTORY": "HISTORY",
    "MIN_MAX": "MIN_MAX",
    "NONE": "NONE"
};

export const StockTypeEnum = {
    "OBSOLETE": "OBSOLETE",
    "SPECIAL_ORDER": "SPECIAL_ORDER",
    "STOCKED": "STOCKED"
};

export const TaxServiceEnum = {
    "DS_TAX": "DS_TAX",
    "MOCK": "MOCK",
    "NONE": "NONE",
    "TAXJAR": "TAXJAR",
    "TAXJAR_SANDBOX": "TAXJAR_SANDBOX"
};

export const UserAccountStatusEnum = {
    "ACTIVE": "ACTIVE",
    "DEACTIVATED": "DEACTIVATED",
    "LOCKED": "LOCKED",
    "PENDING": "PENDING"
};

export const UserStatusTypeEnum = {
    "INVENTORY": "INVENTORY",
    "VEHICLE": "VEHICLE"
};

export const VehicleBidStatus = {
    /**
     * The bid was closed and accepted.
    */
    "ACCEPTED": "ACCEPTED",

    /**
     * The bid is open and can be accepted.
    */
    "OPEN": "OPEN",

    /**
     * The bid was closed and rejected.
    */
    "REJECTED": "REJECTED"
};

export const VehicleOrderBy = {
    "attachmentCount_ASC": "attachmentCount_ASC",
    "attachmentCount_DESC": "attachmentCount_DESC",
    "category_ASC": "category_ASC",
    "category_DESC": "category_DESC",
    "componentCount_ASC": "componentCount_ASC",
    "componentCount_DESC": "componentCount_DESC",
    "dateEntered_ASC": "dateEntered_ASC",
    "dateEntered_DESC": "dateEntered_DESC",
    "imageCount_ASC": "imageCount_ASC",
    "imageCount_DESC": "imageCount_DESC",
    "location_ASC": "location_ASC",
    "location_DESC": "location_DESC",
    "make_ASC": "make_ASC",
    "make_DESC": "make_DESC",
    "model_ASC": "model_ASC",
    "model_DESC": "model_DESC",
    "partcount_ASC": "partcount_ASC",
    "partcount_DESC": "partcount_DESC",
    "receivedDate_ASC": "receivedDate_ASC",
    "receivedDate_DESC": "receivedDate_DESC",
    "retailPrice_ASC": "retailPrice_ASC",
    "retailPrice_DESC": "retailPrice_DESC",
    "status_ASC": "status_ASC",
    "status_DESC": "status_DESC",
    "stockNumber_ASC": "stockNumber_ASC",
    "stockNumber_DESC": "stockNumber_DESC",
    "storeId_ASC": "storeId_ASC",
    "storeId_DESC": "storeId_DESC",
    "topImage_ASC": "topImage_ASC",
    "topImage_DESC": "topImage_DESC",
    "typeField1_ASC": "typeField1_ASC",
    "typeField1_DESC": "typeField1_DESC",
    "typeField2_ASC": "typeField2_ASC",
    "typeField2_DESC": "typeField2_DESC",
    "typeField3_ASC": "typeField3_ASC",
    "typeField3_DESC": "typeField3_DESC",
    "typeField4_ASC": "typeField4_ASC",
    "typeField4_DESC": "typeField4_DESC",
    "type_ASC": "type_ASC",
    "type_DESC": "type_DESC",
    "userStatus_ASC": "userStatus_ASC",
    "userStatus_DESC": "userStatus_DESC",
    "vin_ASC": "vin_ASC",
    "vin_DESC": "vin_DESC",
    "year_ASC": "year_ASC",
    "year_DESC": "year_DESC"
};

export const WebSaleClassEnum = {
    "IN_24_HOURS": "IN_24_HOURS",
    "IN_72_HOURS": "IN_72_HOURS",
    "NONE": "NONE"
};