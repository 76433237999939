export default {
    "name": "InventoryQuestions",
    "kind": "HoudiniQuery",
    "hash": "aa0b1bf1ae5bc4e8ef16f80133608979a9bfd7008ec6fe8adcf472ccdf65d81f",

    "raw": `query InventoryQuestions($modelId: Int, $manufacturerId: Int, $inventoryTypeId: Int, $categoryName: String) {
  inventoryOptions(
    modelId: $modelId
    manufacturerId: $manufacturerId
    inventoryTypeId: $inventoryTypeId
    categoryName: $categoryName
  ) {
    id
    name
    dataType
    choices {
      id
      label
    }
  }
}
`,

    "rootType": "Query",

    "selection": {
        "fields": {
            "inventoryOptions": {
                "type": "InventoryOption",
                "keyRaw": "inventoryOptions(categoryName: $categoryName, inventoryTypeId: $inventoryTypeId, manufacturerId: $manufacturerId, modelId: $modelId)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "Int",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "dataType": {
                            "type": "DataTypeEnum",
                            "keyRaw": "dataType",
                            "visible": true
                        },

                        "choices": {
                            "type": "InventoryOptionChoice",
                            "keyRaw": "choices",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "Int",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "label": {
                                        "type": "String",
                                        "keyRaw": "label",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "modelId": "Int",
            "manufacturerId": "Int",
            "inventoryTypeId": "Int",
            "categoryName": "String"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=6a06b0a7129eb9bf595847334f71f860ca1fad94b5fcbf3655a1eed2f28b5649";