export default {
    "name": "PartSearchResultsData",
    "kind": "HoudiniQuery",
    "hash": "40b885c270fa8ef3c7bb45b71b26aca6920625f5046f6c90b0c966d9c0f4b6d8",

    "raw": `query PartSearchResultsData($pagination: PaginationOptions, $orderBy: [InventorySort!], $filter: InventoryFilter) {
  inventories(pagination: $pagination, orderBy: $orderBy, filter: $filter) {
    pageInfo {
      pageNumber
      pageSize
      totalPages
    }
    totalItems
    items {
      id
      storeId
      attachments {
        id
        public
        rank
        file {
          id
          name
          path: url
          mimetype
          size
        }
      }
      tagNumber
      inventoryType {
        name
        typeLabel1
        typeLabel2
        typeLabel3
        typeLabel4
        id
      }
      manufacturer {
        id
        name
      }
      model {
        id
        name
      }
      parentManufacturer {
        id
        name
      }
      parentModel {
        id
        name
      }
      category {
        id
        name
      }
      vehicleYear
      vehicleMake
      vehicleModel
      bodyStyle
      oemNumber
      locations {
        id
        name
        quantity
        holdQuantity
      }
      wholesalePrice
      retailCorePrice
      retailPrice
      quantity
      description
      notes
      condition
      sku
      side
      vehicle {
        id
        bodyStyle
        externalColor
        externalColorCode
        mileage
      }
      typeField1 {
        label
        data
      }
      typeField2 {
        data
        label
      }
      typeField3 {
        data
        label
      }
      typeField4 {
        data
        label
      }
      status
      userStatus
      dateEntered
      stockCategory
      messageCount
    }
  }
}
`,

    "rootType": "Query",

    "selection": {
        "fields": {
            "inventories": {
                "type": "InventoryList",
                "keyRaw": "inventories(filter: $filter, orderBy: $orderBy, pagination: $pagination)",

                "selection": {
                    "fields": {
                        "pageInfo": {
                            "type": "PageInfo",
                            "keyRaw": "pageInfo",

                            "selection": {
                                "fields": {
                                    "pageNumber": {
                                        "type": "Int",
                                        "keyRaw": "pageNumber",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "pageSize": {
                                        "type": "Int",
                                        "keyRaw": "pageSize",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "totalPages": {
                                        "type": "Int",
                                        "keyRaw": "totalPages",
                                        "nullable": true,
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "totalItems": {
                            "type": "Int",
                            "keyRaw": "totalItems",
                            "visible": true
                        },

                        "items": {
                            "type": "Inventory",
                            "keyRaw": "items",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "storeId": {
                                        "type": "UInt",
                                        "keyRaw": "storeId",
                                        "visible": true
                                    },

                                    "attachments": {
                                        "type": "ItemAttachment",
                                        "keyRaw": "attachments",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "UInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "public": {
                                                    "type": "Boolean",
                                                    "keyRaw": "public",
                                                    "visible": true
                                                },

                                                "rank": {
                                                    "type": "Int",
                                                    "keyRaw": "rank",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "file": {
                                                    "type": "File",
                                                    "keyRaw": "file",

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "Int",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "name": {
                                                                "type": "String",
                                                                "keyRaw": "name",
                                                                "visible": true
                                                            },

                                                            "path": {
                                                                "type": "String",
                                                                "keyRaw": "path",
                                                                "visible": true
                                                            },

                                                            "mimetype": {
                                                                "type": "String",
                                                                "keyRaw": "mimetype",
                                                                "visible": true
                                                            },

                                                            "size": {
                                                                "type": "Int",
                                                                "keyRaw": "size",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "tagNumber": {
                                        "type": "String",
                                        "keyRaw": "tagNumber",
                                        "visible": true
                                    },

                                    "inventoryType": {
                                        "type": "InventoryType",
                                        "keyRaw": "inventoryType",

                                        "selection": {
                                            "fields": {
                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "typeLabel1": {
                                                    "type": "String",
                                                    "keyRaw": "typeLabel1",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "typeLabel2": {
                                                    "type": "String",
                                                    "keyRaw": "typeLabel2",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "typeLabel3": {
                                                    "type": "String",
                                                    "keyRaw": "typeLabel3",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "typeLabel4": {
                                                    "type": "String",
                                                    "keyRaw": "typeLabel4",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "UInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "manufacturer": {
                                        "type": "Manufacturer",
                                        "keyRaw": "manufacturer",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "Int",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "model": {
                                        "type": "Model",
                                        "keyRaw": "model",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "Int",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "parentManufacturer": {
                                        "type": "Manufacturer",
                                        "keyRaw": "parentManufacturer",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "Int",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "parentModel": {
                                        "type": "Model",
                                        "keyRaw": "parentModel",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "Int",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "category": {
                                        "type": "Category",
                                        "keyRaw": "category",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "Int",
                                                    "keyRaw": "id",
                                                    "visible": true,
                                                    "nullable": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "vehicleYear": {
                                        "type": "UInt",
                                        "keyRaw": "vehicleYear",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "vehicleMake": {
                                        "type": "String",
                                        "keyRaw": "vehicleMake",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "vehicleModel": {
                                        "type": "String",
                                        "keyRaw": "vehicleModel",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "bodyStyle": {
                                        "type": "String",
                                        "keyRaw": "bodyStyle",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "oemNumber": {
                                        "type": "String",
                                        "keyRaw": "oemNumber",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "locations": {
                                        "type": "InventoryLocation",
                                        "keyRaw": "locations",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "UInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "quantity": {
                                                    "type": "Decimal6",
                                                    "keyRaw": "quantity",
                                                    "visible": true
                                                },

                                                "holdQuantity": {
                                                    "type": "Decimal6",
                                                    "keyRaw": "holdQuantity",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "wholesalePrice": {
                                        "type": "Decimal2",
                                        "keyRaw": "wholesalePrice",
                                        "visible": true
                                    },

                                    "retailCorePrice": {
                                        "type": "Decimal2",
                                        "keyRaw": "retailCorePrice",
                                        "visible": true
                                    },

                                    "retailPrice": {
                                        "type": "Decimal2",
                                        "keyRaw": "retailPrice",
                                        "visible": true
                                    },

                                    "quantity": {
                                        "type": "Decimal6",
                                        "keyRaw": "quantity",
                                        "visible": true
                                    },

                                    "description": {
                                        "type": "String",
                                        "keyRaw": "description",
                                        "visible": true
                                    },

                                    "notes": {
                                        "type": "String",
                                        "keyRaw": "notes",
                                        "visible": true
                                    },

                                    "condition": {
                                        "type": "String",
                                        "keyRaw": "condition",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "sku": {
                                        "type": "UInt",
                                        "keyRaw": "sku",
                                        "visible": true
                                    },

                                    "side": {
                                        "type": "SideEnum",
                                        "keyRaw": "side",
                                        "visible": true
                                    },

                                    "vehicle": {
                                        "type": "Vehicle",
                                        "keyRaw": "vehicle",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "UInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "bodyStyle": {
                                                    "type": "String",
                                                    "keyRaw": "bodyStyle",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "externalColor": {
                                                    "type": "String",
                                                    "keyRaw": "externalColor",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "externalColorCode": {
                                                    "type": "String",
                                                    "keyRaw": "externalColorCode",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "mileage": {
                                                    "type": "UInt",
                                                    "keyRaw": "mileage",
                                                    "nullable": true,
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "typeField1": {
                                        "type": "InventoryTypeField",
                                        "keyRaw": "typeField1",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "label": {
                                                    "type": "String",
                                                    "keyRaw": "label",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "data": {
                                                    "type": "String",
                                                    "keyRaw": "data",
                                                    "nullable": true,
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "typeField2": {
                                        "type": "InventoryTypeField",
                                        "keyRaw": "typeField2",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "data": {
                                                    "type": "String",
                                                    "keyRaw": "data",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "label": {
                                                    "type": "String",
                                                    "keyRaw": "label",
                                                    "nullable": true,
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "typeField3": {
                                        "type": "InventoryTypeField",
                                        "keyRaw": "typeField3",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "data": {
                                                    "type": "String",
                                                    "keyRaw": "data",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "label": {
                                                    "type": "String",
                                                    "keyRaw": "label",
                                                    "nullable": true,
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "typeField4": {
                                        "type": "InventoryTypeField",
                                        "keyRaw": "typeField4",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "data": {
                                                    "type": "String",
                                                    "keyRaw": "data",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "label": {
                                                    "type": "String",
                                                    "keyRaw": "label",
                                                    "nullable": true,
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "status": {
                                        "type": "StatusEnum",
                                        "keyRaw": "status",
                                        "visible": true
                                    },

                                    "userStatus": {
                                        "type": "String",
                                        "keyRaw": "userStatus",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "dateEntered": {
                                        "type": "DateTime",
                                        "keyRaw": "dateEntered",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "stockCategory": {
                                        "type": "StockCategory",
                                        "keyRaw": "stockCategory",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "messageCount": {
                                        "type": "Int",
                                        "keyRaw": "messageCount",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "pagination": "PaginationOptions",
            "orderBy": "InventorySort",
            "filter": "InventoryFilter"
        },

        "types": {
            "PaginationOptions": {
                "pageNumber": "Int",
                "pageSize": "Int"
            },

            "StringFilter": {
                "eq": "String",
                "gt": "String",
                "gte": "String",
                "lt": "String",
                "lte": "String",
                "ne": "String"
            },

            "InventoryFieldFilter": {
                "name": "String",
                "value": "StringFilter"
            },

            "DateFilter": {
                "eq": "Date",
                "gt": "Date",
                "gte": "Date",
                "lt": "Date",
                "lte": "Date",
                "ne": "Date"
            },

            "Decimal2Filter": {
                "eq": "Decimal2",
                "gt": "Decimal2",
                "gte": "Decimal2",
                "lt": "Decimal2",
                "lte": "Decimal2"
            },

            "IntFilter": {
                "eq": "Int",
                "gt": "Int",
                "gte": "Int",
                "lt": "Int",
                "lte": "Int",
                "ne": "Int"
            },

            "Decimal6Filter": {
                "eq": "Decimal6",
                "gt": "Decimal6",
                "gte": "Decimal6",
                "lt": "Decimal6",
                "lte": "Decimal6",
                "ne": "Decimal6"
            },

            "SmartSearchFilter": {
                "keyword": "String",
                "moduleIds": "Int"
            },

            "InventoryFilter": {
                "additionalFields": "InventoryFieldFilter",
                "assemblyManufacturerId": "Int",
                "assemblyModelId": "Int",
                "category": "String",
                "condition": "String",
                "customFields": "InventoryFieldFilter",
                "dateSold": "DateFilter",
                "defaultVendorCode": "String",
                "defaultVendorId": "Int",
                "description": "String",
                "distributorCorePrice": "Decimal2Filter",
                "distributorPrice": "Decimal2Filter",
                "enteredByUser": "IntFilter",
                "ids": "Int",
                "inventoryTypeId": "IntFilter",
                "isCore": "Boolean",
                "jobberCorePrice": "Decimal2Filter",
                "jobberPrice": "Decimal2Filter",
                "keyword": "String",
                "location": "String",
                "manufacturerId": "Int",
                "modelId": "Int",
                "notes": "String",
                "partNumber": "String",
                "partNumberStripped": "String",
                "quantity": "Decimal6Filter",
                "quantityAvailable": "Decimal6Filter",
                "quantityOnHold": "Decimal6Filter",
                "quantityOnOrder": "Decimal6Filter",
                "replenishable": "Boolean",
                "shippingHeight": "Decimal2Filter",
                "shippingLength": "Decimal2Filter",
                "shippingWidth": "Decimal6Filter",
                "side": "SideEnum",
                "skuNumber": "Int",
                "smartSearchFilters": "SmartSearchFilter",
                "statuses": "InventorySearchStatus",
                "stores": "Int",
                "tagNumber": "String",
                "typeField1": "String",
                "typeField2": "String",
                "typeField3": "String",
                "typeField4": "String",
                "userStatus": "String",
                "vehicleManufacturerId": "Int",
                "vehicleModelId": "Int",
                "vendorLeadTime": "IntFilter",
                "vin": "String",
                "weight": "Decimal6Filter",
                "year": "IntFilter"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=9b08f25bee9ea5c67b118e793243d2467f58f6f9d9c7bd1875c92514773affe5";